// Build information, automatically generated by `ng-info`
const build = {
    version: "4.9.1",
    timestamp: "Wed Nov 13 2024 18:38:17 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "master",
        hash: "deb6b2",
        fullHash: "deb6b284e9457fa6a7f75cef532dd574f9a32908"
    }
};

export default build;